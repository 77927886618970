<template>
  <v-container id="user-profile" fluid tag="section">
    <v-row justify="center">
        <dialog-message
          :dialog_message="dialog_message"
          :message="message"
        ></dialog-message>
      <v-col cols="12">
        <base-material-card>
          <template v-slot:heading>
            <div class="display-2 font-weight-light">{{ $t('title.CompanyProfile') }}</div>
          </template>
          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col cols="12" md="6">
                      <v-text-field
                        v-model="item.name"
                        :readonly="role === UserRole.CompanyUser"
                        :rules="fieldRules"
                        :label="$t('company_mgr.name')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="item.company_id"
                        :readonly="role === UserRole.CompanyUser"
                        :rules="companyIDRules" 
                        :label="$t('company_mgr.ID')"
                        :hint="company_id_hint"
                        validate-on-blur 
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="item.phone"
                        :readonly="role === UserRole.CompanyUser"
                        :rules="fieldRules"
                        :label="$t('company_mgr.phone')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="item.max_urban_renewal"
                        readonly
                        :label="$t('user_mgr.max_urban_renewal')"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="item.max_issue"
                        readonly
                        :label="$t('user_mgr.max_issue')"
                      ></v-text-field>
                    </v-col>
              </v-row>
            </v-container>
          </v-form>
            <v-col class="text-right">
                <v-dialog v-model="dialog_form" max-width="500px" @click:outside="close">
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark class="mb-2" v-on="on" @click="createUser">
                        <v-icon left>mdi-pencil-plus</v-icon>
                        {{ $t("user_mgr.createUser") }}
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                        <span class="headline">{{ $t("user_mgr.createUser") }}</span>
                        </v-card-title>
                        <v-form ref="userForm" v-model="valid" lazy-validation>
                        <v-card-text>
                            <v-container>
                            <v-row dense>
                                <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="user.username"
                                    :rules="fieldRules"
                                    :label="$t('user_mgr.username')"
                                ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="user.first_name"
                                    :rules="fieldRules"
                                    :label="$t('user_mgr.first_name')"
                                ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="user.password1"
                                    :rules="fieldRules"
                                    :type="showPassword ? 'text' : 'password'"
                                    prepend-icon="mdi-lock"
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    @click:append="showPassword = !showPassword"
                                    :label="$t('user_mgr.password1')"
                                ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="user.password2"
                                    :rules="fieldRules"
                                    :type="showPassword ? 'text' : 'password'"
                                    prepend-icon="mdi-lock"
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    @click:append="showPassword = !showPassword"
                                    :label="$t('user_mgr.password2')"
                                ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                <v-text-field
                                    v-model="user.nickname"
                                    :rules="fieldRules"
                                    :label="$t('user_mgr.nickname')"
                                ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                <v-text-field
                                    :label="$t('user_mgr.email')"
                                    class="purple-input"
                                    v-model="user.email"
                                />
                                </v-col>

                                <v-col cols="12" md="6">
                                <v-text-field
                                    :label="$t('user_mgr.phone')"
                                    :rules="fieldRules"
                                    class="purple-input"
                                    v-model="user.phone"
                                />
                                </v-col>

                                <v-col cols="12" md="6">
                                <v-text-field
                                    :label="$t('user_mgr.line_account')"
                                    class="purple-input"
                                    v-model="user.line_account"
                                />
                                </v-col>
                                <v-col cols="12" md="6">
                                <v-text-field
                                    :label="$t('user_mgr.company_name')"
                                    class="purple-input"
                                    readonly
                                    v-model="item.name"
                                />
                                </v-col>
                                <v-col cols="6">
                                <v-text-field
                                    :label="$t('user_mgr.job_title')"
                                    class="purple-input"
                                    v-model="user.job_title"
                                />
                                </v-col>
                            </v-row>
                            </v-container>
                        </v-card-text>
                        </v-form>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">
                                {{ $t("common.cancel") }}
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="saveUser">
                                {{ $t("common.save") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
            <v-col cols="12">
                    <v-list
                    subheader
                    >
                    <v-subheader class="v-label theme--light">
                        {{ $t('user_role.CompanyAdmin') }}
                    </v-subheader>
                    <v-divider inset></v-divider>
                    <v-list-item v-for="admin in admins" :key="admin.id">
                        <v-list-item-content class="py-0">
                            <v-list-item-title v-text="admin.username"></v-list-item-title>
                        </v-list-item-content>
                        <template v-if="role === UserRole.CompanyAdmin">
                            <v-list-item-action class="ma-0 flex-row">
                                <v-btn color="orange" class="mx-1" small @click="setCompanyUser(admin.id)">
                                    {{ $t('company_mgr.setUser') }}
                                </v-btn>
                                <v-btn color="red" class="mx-1" small
                                    @click="removeUser(admin.id)"
                                    >{{ $t("common.delete") }}
                                </v-btn>
                            </v-list-item-action>
                        </template>
                    </v-list-item>
                    <v-subheader class="v-label theme--light">
                        {{ $t('user_role.CompanyUser') }}
                    </v-subheader>
                    <v-divider inset></v-divider>
                    <v-list-item v-for="user in users" :key="user.id">
                        <v-list-item-content class="py-0">
                            <v-list-item-title v-text="user.username"></v-list-item-title>
                        </v-list-item-content>
                        <template v-if="role === UserRole.CompanyAdmin">
                            <v-list-item-action class="ma-0 flex-row">
                                <v-btn color="ocean" class="mx-1" small @click="setCompanyAdmin(user.id)">
                                    {{ $t('company_mgr.setAdmin') }}
                                </v-btn>
                                <v-btn color="red" class="mx-1" small
                                    @click="removeUser(user.id)"
                                    >{{ $t("common.delete") }}
                                </v-btn>
                            </v-list-item-action>
                        </template>
                    </v-list-item>
                    </v-list>
                </v-col>
            <v-col cols="12" class="text-right" v-if="role === UserRole.CompanyAdmin">
                <v-btn color="success" :disabled="loading" class="mr-0" @click="update">{{ $t('common.save') }}</v-btn>
            </v-col>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { UserRole } from '@/definition'
export default {
  data: vm => ({
    UserRole: UserRole,
    dialog_message: false,
    dialog_form: false,
    loading: false,
    item: {
      name: "",
      company_id: "",
      max_urban_renewal: 0,
      max_issue: 0,
      admins: [],
      users: [],
    },
    admins: [],
    users: [],
    message: {
      title: "",
      text: ""
    },
    showPassword: false,
    valid: true,
    user: {
      username: "",
      first_name: "",
      password1: "",
      password2: "",
      email: "",
      nickname: "",
      phone: "",
      max_urban_renewal: 0,
      line_account: "",
      company_name: "",
      job_title: "",
      company: null,
    },
    fieldRules: [v => !!v || vm.$i18n.t("common.missing")],
    company_id_hint: "",
    idValid: true,
    currentID: '',
    companyIDRules: [v => !!v || vm.$i18n.t("common.missing"), v => vm.idValid || vm.$i18n.t("signup.invalidCompanyID"), v => v.length==8 || vm.$i18n.t("signup.invalidFormat")],
  }),
  computed: {
    ...mapState(["token", "company", "role"])
  },
  watch:{
    'item.company_id':{
        handler: function(val){
            if(val!=''){
                this.checkCompanyID()
            } else {
                this.company_id_hint = ""
            }
        }
    }
  },
  methods: {
    ...mapMutations(["setTitle", "setUsername"]),
    close() {
      this.dialog_form = false;
      this.loading = false;
    },
    getApi() {
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/company/getProfile/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + this.token
        }
      };

      this.axios
        .get(url, config)
        .then(function(response) {
          currentObj.loading = false;
          currentObj.setResult(response.data);
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("get-api-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    setResult(data) {
      this.item = data.company;
      this.currentID = data.company.company_id
      this.item.admins = data.admin
      this.item.users = data.user
      this.admins = data.admin
      this.users = data.user
    },
    setCompanyAdmin(user_id){
      let user_index = this.item.users.findIndex(user=>{ return user.id == user_id })
      this.item.admins.push(this.item.users[user_index])
      this.item.users.splice(user_index,1)
      this.setUsers()
    },
    setCompanyUser(user_id){
      let user_index = this.item.admins.findIndex(user=>{ return user.id == user_id })
      this.item.users.push(this.item.admins[user_index])
      this.item.admins.splice(user_index,1)
      this.setUsers()
    },
    setUsers(){
      this.admins = this.item.admins
      this.users = this.item.users
    },
    createUser() {
      if(this.$refs.userForm){
        this.$refs.userForm.resetValidation()
      }
      this.user.username = "";
      this.user.first_name="";
      this.user.password1 = "";
      this.user.password2 = "";
      this.user.email = "";
      this.user.nickname = "";
      this.user.phone = "";
      this.user.line_account = "";
      this.user.company_name = this.item.name;
      this.user.job_title = "";
    },
    checkAdmin(){
      let valid = true
      if(this.item.admins.length==0){
        valid = false
        alert(this.$i18n.t('company_mgr.noAdmin'))
      }
      return valid
    },
    update() {
      let adminValid = this.checkAdmin()
      if(!adminValid){
        return
      }
      // create hn account
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/company/" + this.company + "/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token
        }
      };
      let data = {
        name: this.item.name,
        phone: this.item.phone,
        admin: this.item.admins,
        user: this.item.users,
      };
      if (this.item.company_id != this.currentID){
        data['company_id'] = this.item.company_id
      }
      this.axios
        .patch(url, data, config)
        .then(function(response) {
          currentObj.loading = false;
          alert("送出成功");
          currentObj.getApi();
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("update-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    checkPassword(password1, password2) {
      let is_valid = true;
      if (password1 != password2) {
        alert(this.$i18n.t("user_mgr.passwordNotMatch"));
        is_valid = false;
      } else if (password1 == "") {
        alert(this.$i18n.t("user_mgr.missingPassword"));
        is_valid = false;
      }
      return is_valid;
    },
    saveUser() {
        // create hn account
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/backend/company/createCompanyUser/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token
        }
      };
      let data_valid = this.checkPassword(
        this.user.password1,
        this.user.password2
      );
      if (!data_valid) {
        this.loading = false;
        return;
      }
      let data = {
        username: this.user.username,
        first_name: this.user.first_name,
        password: this.user.password1,
        email: this.user.email,
        nickname: this.user.nickname,
        phone: this.user.phone,
        role: this.user.role,
        line_account: this.user.line_account,
        company_name: this.user.company_name,
        job_title: this.user.job_title
      };
      this.axios
        .post(url, data, config)
        .then(function(response) {
          currentObj.loading = false;
          alert("送出成功");
          currentObj.getApi();
          currentObj.close();
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("save-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    checkIfAdmin(user_id){
      let valid = true
      let user_index = this.item.admins.findIndex(user=>{ return user.id == user_id })
      if(user_index != -1 && this.item.admins.length==1){
        valid = false
        alert(this.$i18n.t('company_mgr.noAdmin'))
      }
      return valid
    },
    removeUser(user_id) {
      if(!confirm(
          this.$i18n.t('common.confirm') + this.$i18n.t('common.delete')+ "？"
        )
      ) {
        return;
      }
      let adminValid = this.checkIfAdmin(user_id)
      if(!adminValid){
        return
      }
      // create hn account
      this.loading = true;
      const currentObj = this;
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url =
        process.env.VUE_APP_SERVER_URL + "/backend/company/" + this.company + "/removeCompanyUser/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "token " + this.token
        }
      };
      let data = {
        user_id: user_id,
      };
      this.axios
        .patch(url, data, config)
        .then(function(response) {
          currentObj.loading = false;
          alert("刪除成功");
          currentObj.getApi();
        })
        .catch(function(error) {
          console.log(error);
          console.log(error.response);
          currentObj.loading = false;
          currentObj.message.title = currentObj.$t("remove-fail");
          currentObj.message.text = JSON.stringify(error.response.data);
          currentObj.dialog_message = !currentObj.dialog_message;
        });
    },
    checkCompanyID() {
        const currentObj = this;
        const url =
            process.env.VUE_APP_SERVER_URL +
            "/backend/createUser/checkCompanyID/";
        let config = {
            headers: {
                "Content-Type": "application/json",
                // Authorization: "Token " + this.token,
            },
            params: {
                company_id: this.item.company_id
            },
        };

        this.axios
            .get(url, config)
            .then(function (response) {
            currentObj.loading = false;
            let request_data = response.data
            if (request_data.status == 'OK'){
                currentObj.company_id_hint = currentObj.$t("signup.validCompanyID")
                currentObj.idValid = true
            } else {
                if(currentObj.item.company_id != currentObj.currentID){
                  currentObj.company_id_hint = currentObj.$t("signup.invalidCompanyID")
                  currentObj.idValid = false
                } else {
                  currentObj.company_id_hint = ""
                }
            }
            })
            .catch(function (error) {
            console.log(error);
            console.log(error.response);
            currentObj.loading = false;
            currentObj.message.title = currentObj.$t("get-api-fail");
            currentObj.message.text = JSON.stringify(error.response.data);
            currentObj.dialog_message = !currentObj.dialog_message;
            });
    },
  },
  mounted() {
    this.setTitle(this.$i18n.t("title.CompanyProfile"));
    this.getApi();
  }
  //
};
</script>
